<template>
  <div class="bigData">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="bigData_box">
      <div class="bigDatas">
        <div v-for="(item, index) in bigDatas" :key="index" class="item">
          <div class="img_content" @click="onPlay(item)">
            <img :src="item.imgUrl" alt="" />
            <!-- <div>
              <img src="@/assets/images/bigData/icon_play.png" alt="" />
            </div> -->
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <Footer2 />
    <el-dialog :visible.sync="isShow" width="100vw" :before-close="handleClose">
      <video
        ishivideo="true"
        autoplay="true"
        isrotate="true"
        autoHide="true"
        controls="controls"
        class="videoa"
        v-if="isShow"
      >
        <source src="@/assets/images/bigData/1.mp4" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>

<script>
import { bigDatas } from "@/assets/js/staticMobile.js";
export default {
  name: "BigData",
  data() {
    return {
      bigDatas,
      isShow: false,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/5.png"),
        },
      ],
    };
  },
  methods: {
    onPlay(item) {
      console.log(item);
      // this.isShow = true;
    },
    handleClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.bigData {
  .bigData_box {
    padding: 0 15px 30px;
    .bigDatas {
      display: flex;
      flex-direction: column;

      .item {
        margin-top: 15px;
        border: 1px solid #006eff;
        border-radius: 2px;
        .img_content {
          position: relative;
          > img {
            width: 100%;
            display: block;
          }
          > div {
            background-color: #00000033;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            > img {
              width: 34px;
              height: 34px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .name {
          line-height: 50px;
          font-weight: 200;
          font-size: 15px;
          letter-spacing: 1.5px;
          color: #000000;
          font-family: "AlibabaPuHuiTi-Light";
        }
      }
    }
  }
  ::v-deep .el-dialog {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .el-dialog__header {
      padding: 15px;
      .el-dialog__headerbtn {
        top: 8px;
      }
    }
    .el-dialog__body {
      padding: 0;
      position: relative;
      .videoa {
        width: 100%;
      }
    }
  }
}
</style>
