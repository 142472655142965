export const caseExhibitions = [
  {
    name: "台州科技馆",
    imgUrl: require("@/assets/images/home/caseExhibition/case1.png"),
    content: [
      {
        title: "智能化信息化项目",
        list: [
          "智能化基础实施：云服务、计算机网络、客流监控；",
          "公众服务系统：票务系统、网站、公众号、预约系统、自助机、道闸。",
          "场馆管理系统：场馆综合管理系统、客流统计分析系统、场馆可视化统计分析决策系统。",
        ],
      },
    ],
  },
  {
    name: "宁德科技馆",
    imgUrl: require("@/assets/images/home/caseExhibition/case2.png"),
    content: [
      {
        title: "智能化信息化项目",
        list: [
          "智能化基础实施：综合布线、机房、云服务、计算机网络、广播系统、智能中控系统、视频监控、客流监控、门禁系统；",
          "公众服务系统：票务系统、网站、公众号、公众预约系统、智能导览系统、360 全景展示、自助机、无线讲解系统、安检道闸。",
          "场馆管理系统：场馆综合管理系统、OA、展陈管理系统、客流统计分析系统、大数据应用系统、场馆可视化统计分析决策系统。",
        ],
      },
    ],
  },
  {
    name: "漩门湾",
    imgUrl: require("@/assets/images/home/caseExhibition/case3.png"),
    content: [
      {
        title: "漩门湾智慧旅游",
        list: [
          "智慧票务系统、自助服务机、公众预约系统、支付结算系统、统计分析系统。",
        ],
      },
      {
        title: "浙江数字经济展厅",
        list: [
          "公众在线预约系统、客流区域热度统计系统、客流统计分析系统、智能导览系统、可视化平台系统。",
        ],
      },
    ],
  },
  {
    name: "城市运行管理服务平台",
    imgUrl: require("@/assets/images/home/caseExhibition/case4.png"),
    content: [
      {
        list: [
          "本项目建设遵循浙江省政府数字化改革“四横四纵”体系，基于城市管理基础设施和资源、“城市大脑”各个基础资源和现有各个城市管理信息化业务系统，满足住建部和省建设厅城市综合管理服务平台标准要求，规划城市运行数据仓、城市运行一张图、城市管家三大模块。",
        ],
      },
    ],
  },
  {
    name: "智慧城市基础设施提升工程",
    imgUrl: require("@/assets/images/home/caseExhibition/case5.png"),
    content: [
      {
        list: [
          "数字经信数据仓（数据共享及中心数据库建设）；企业数字化服务系统；大数据应用系统；全域产业地图；全域五色项目地图；企业多维度指标量化评价模型及企业画像展示及多维度指标排名展示系统；大数据产业监测系统；大数据可视化展示决策系统。",
        ],
      },
    ],
  },
  {
    name: "市场安全综合监管系统—网络市场智治系统",
    imgUrl: require("@/assets/images/home/caseExhibition/case6.png"),
    content: [
      {
        list: [
          "网络市场智治系统主要建设内容为软件和数据库建设。具体建设内容为网络经营主体综合信息数据库、网络经营主体健康度评估模型、网络经营主体风险预警模型、网络监管数字桌面、领导决策辅助可视化应用等",
        ],
      },
    ],
  },
];

export const introduces = [
  {
    title: "需求分析",
    content: "项目经理一对一沟通，确定产品开发需求，给予报价表和周期",
    imgUrl: require("@/assets/images/home/introduce/1.png"),
  },
  {
    title: "视觉与架构设计",
    content: "UI设计、原型设计、软件基础架构设计",
    imgUrl: require("@/assets/images/home/introduce/2.png"),
  },
  {
    title: "系统集成开发",
    content: "客户验收完整成功，确认功能需求。前后端程序开发，系统架构设计",
    imgUrl: require("@/assets/images/home/introduce/3.png"),
  },
  {
    title: "软件功能测试",
    content: "进行单元测试、系统测试、集成测试、压力测试，提供测试报告",
    imgUrl: require("@/assets/images/home/introduce/4.png"),
  },
  {
    title: "信息安全测评",
    content: "等保测评、验收测试",
    imgUrl: require("@/assets/images/home/introduce/5.png"),
  },
  {
    title: "项目上线",
    content: "客户确认服务器配置井发需求，上线部署，交付开发文件",
    imgUrl: require("@/assets/images/home/introduce/6.png"),
  },
  {
    title: "后期维护",
    content: "定期对软件进行维护，根据用户出现的新需求，进行应用软件程序的修改",
    imgUrl: require("@/assets/images/home/introduce/7.png"),
  },
];

export const advantages = [
  {
    title: "丰富的开发经验",
    content: "2011年起航至今，十余年开发经验的沉淀，保证技术过硬。",
  },
  {
    title: "有效的解决方案",
    content: "针对问题、分析问题、解决问题并能有效实施，提供解决方案",
  },
  {
    title: "专业的技术团队",
    content: "富有创造力的专业技术团队，高效率开发产品，为企业节省开发时间。",
  },
  {
    title: "即时的服务支持",
    content: "7*24小时多渠道服务支持，确保及时为客户解决问题。",
  },
  {
    title: "稳定的客户群体",
    content: "服务于众多政府、机关、大型企事业单位，值得信赖。",
  },
];

export const bigDatas = [
  {
    name: "数字孪生智能中台",
    imgUrl: require("@/assets/images/bigData/1.svg"),
  },
  {
    name: "博物馆动态监控平台",
    imgUrl: require("@/assets/images/bigData/2.svg"),
  },
  {
    name: "博物馆全景管控服可视化平台",
    imgUrl: require("@/assets/images/bigData/3.svg"),
  },
  {
    name: "智慧城市基础设施提升工程",
    imgUrl: require("@/assets/images/bigData/4.svg"),
  },
  {
    name: "城市运行管理服务平台",
    imgUrl: require("@/assets/images/bigData/5.svg"),
  },
  {
    name: "市场安全综合监管系统",
    imgUrl: require("@/assets/images/bigData/6.svg"),
  },
  {
    name: "慢病随访管理平台",
    imgUrl: require("@/assets/images/bigData/7.svg"),
  },
  {
    name: "食品安全监控平台",
    imgUrl: require("@/assets/images/bigData/8.svg"),
  },
  {
    name: "数智政务可视化数据平台",
    imgUrl: require("@/assets/images/bigData/9.svg"),
  },
];

export const welfares = [
  {
    name: "节日福利",
    imgUrl: require("@/assets/images/recruit/icon_1.png"),
  },
  {
    name: "加班补助",
    imgUrl: require("@/assets/images/recruit/icon_2.png"),
  },
  {
    name: "年终奖",
    imgUrl: require("@/assets/images/recruit/icon_3.png"),
  },
  {
    name: "员工旅游",
    imgUrl: require("@/assets/images/recruit/icon_4.png"),
  },
  {
    name: "五险",
    imgUrl: require("@/assets/images/recruit/icon_5.png"),
  },
  {
    name: "带薪年假",
    imgUrl: require("@/assets/images/recruit/icon_6.png"),
  },
];

// name:职位
// price:薪酬范围
// experience:工作经验
// education:学历要求
// number:招聘人数
// workLocation:工作地点
// releaseTime:发布时间
// jobDescription:职位描述
// jobRequirements:岗位要求
export const positions = [
  {
    name: "多媒体策划",
    price: "5-6K",
    experience: "1-3年",
    education: "不限",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "项目经理",
    price: "5-8K",
    experience: "1-3年",
    education: "不限",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "销售经理",
    price: "4-8K",
    experience: "不限",
    education: "大专",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "产品助理",
    price: "4-6K",
    experience: "不限",
    education: "大专",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "U3D",
    price: "6-8K",
    experience: "不限",
    education: "不限",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "Java",
    price: "6-8K",
    experience: "不限",
    education: "大专",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: ".NET",
    price: "6-8K",
    experience: "不限",
    education: "不限",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
  {
    name: "系统集成硬件工程师",
    price: "5-10K",
    experience: "1-3年",
    education: "大专",
    number: "1人",
    workLocation: "台州市",
    releaseTime: "2022-08-12",
    jobDescription: "电子政务/集成类销售；大客户销售;",
    jobRequirements: [
      "大专及以上学历。",
      "热爱销售工作。",
      "具备良好的市场分析能力、判断能力及独立开拓市场的能力，有良好的客户服务意识。",
      "注重团队合作，具备良好的人际沟通能力、分析解决问题能力。",
    ],
  },
];
